<template>
  <page-layout :retailer-id="retailerId" active-menu-item="account:plan">
    <main-column :breadcrumbs="breadcrumbs" page-title="Account &amp; Plan">
      <div class="card">
        <div class="card-content">
          <h2 class="title is-2"> Account &amp; Plan </h2>
          <div class="columns" v-if="loaded">
            <div class="column is-full">
              <div class="card p-5">
                <div class="columns is-multiline">
                  <div class="column is-one-quarter-desktop">
                    <h2 class="title is-2 has-text-primary mb-2">{{ billingSchemeName }}</h2>
                    <p v-if="isTopLevelPlan">Unlimited Negotiations</p>
                    <p v-else :class="{ expired: planIsInError }">
                      <b>{{ billingCycleUsedNibbles }}</b> of {{ billingCycleMaxNibbles }} Negotiations
                      <span v-if="showMonthText" class="month-text"> / mo</span>
                    </p>
                  </div>
                  <div class="column is-one-half-desktop">
                    <h2 class="title is-2 mb-2">
                      ${{ subscriptionFee }}<span class="month-text"> / mo </span>
                    </h2>
                    <p>{{ commissionText }}</p>
                  </div>
                </div>
                <div v-if="usageData" class="card mb-5"/>
                  <banner v-if="usageData" :banner-type="usageType">
                    <template v-slot:title>
                      {{ usageData.changePlanText }}
                      <span>{{ usageData.nextPlan }}</span>
                    </template>
                    <template v-slot:icon>
                      <material-icon :icon="usageData.icon" size="xlarge" class="icon"/>
                    </template>
                    <template v-slot:text>
                      {{ usageData.changePlanDetails }}
                    </template>
                    <template v-slot:button>
                      <b-button type="is-primary is-rounded" @click="chooseAPlan">
                        {{ usageData.buttonText }}
                      </b-button>
                    </template>
                  </banner>
                </div>

              <div class="column is-half mt-3">
                <b-field label="Account Type">
                  <b-input :placeholder="paymentType" disabled/>
                </b-field>
                <b-field label="Email">
                  <b-input :placeholder="email" disabled/>
                </b-field>
                <b-field label="Password" v-if="!isShopifyStore" >
                  <div v-if="password_requested === false && password_requested_error === false">
                    <b-button type="is-rounded" @click="changePassword">Change Password</b-button>
                  </div>
                  <div v-if="password_requested" class="message password-change-message is-success">
                      <p>Please check your email for instructions on completing your password change. If you do not receive it within a few minutes, check your spam folder.</p>
                  </div>
                  <div v-if="password_requested_error" class="message password-change-message is-danger">
                    <p>There was an error requesting a password reset. Please try again later.</p>
                  </div>
                </b-field>
                <b-field label="Website">
                  <b-input :placeholder="website" disabled/>
                </b-field>
                <b-field label="Timezone Code">
                  <b-input :placeholder="timezone_code" disabled/>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
    </main-column>
    <update-plan-modal v-if="pendingScheme" ref="updateModal" :retailer-id="retailerId" :billingSchemeId="pendingScheme.billing_scheme_id"></update-plan-modal>
  </page-layout>
</template>

<script>
import MainColumn from '@/components/MainColumn'
import PageLayout from '@/components/PageLayout'
import MaterialIcon from '@/components/MaterialIcon'
import UpdatePlanModal from '@/components/account/UpdatePlanModal'
import Banner from '@/components/Banner'
import nibble from '@/utils/nibble'

export default {
  name: 'AccountPlan',
  components: { MaterialIcon, MainColumn, PageLayout, Banner, UpdatePlanModal },
  data () {
    return {
      retailerId: this.$route.params.retailerId != null ? parseInt(this.$route.params.retailerId) : null,
      shopifyChargeId: this.$route.query.charge_id,
      loaded: false,
      pricingPlanIdentifier: 'starter',
      billingSchemeName: 'STARTER',
      billingSchemeStatus: '',
      subscriptionFee: '0',
      billingCycleUsedNibbles: 0,
      billingCycleType: '',
      billingCycleUsageCap: null,
      billingCycleMaxNibbles: 100,
      paymentType: '',
      usedFreeNibbles: 0,
      freeNibbles: 100,
      usageFeePercentage: '0',
      email: '', // this.$store.state.user.email != null ? this.$store.state.user.email : '',
      website: '',
      pendingScheme: null,
      timezone_code: 'UTC',
      password_requested: false,
      password_requested_error: false
    }
  },
  computed: {
    breadcrumbs () {
      return [
        {
          link: `/retailers/${this.retailerId}/account/plan`,
          title: 'Profile & Plan'
        }
      ]
    },
    commissionText () {
      return this.usageFeePercentage === '0' ? 'No Commissions' : `+${this.usageFeePercentage}% converted sales up to a cap of $${this.billingCycleUsageCap}`
    },
    isTopLevelPlan () {
      return this.pricingPlanIdentifier === 'enterprise' || this.pricingPlanIdentifier === 'premium'
    },
    planIsInError () {
      return this.billingSchemeStatus !== 'active'
    },
    planIsOverUsageCap () {
      return this.billingSchemeStatus === 'nibble_cap_reached' || this.billingSchemeStatus === 'usage_cap_reached'
    },
    showMonthText () {
      return (this.pricingPlanIdentifier === 'basic' || this.pricingPlanIdentifier === 'professional')
    },
    usageData () {
      if (this.pendingScheme != null) {
        return {
          icon: 'error_outline',
          changePlanText: `Your upgrade to ${this.pendingScheme.name} is pending`,
          changePlanDetails: 'A plan upgrade is pending for you, but it needs to be approved.',
          buttonText: 'Approve Upgrade',
          nextPlan: null
        }
      } else if (this.planIsOverUsageCap) {
        return {
          icon: 'error_outline',
          changePlanText: `Your ${this.billingSchemeName} Plan reached its monthly limit`,
          changePlanDetails: 'Nibble will be unavailable on your store until your next month starts. Upgrade your plan now to keep using Nibble.',
          buttonText: 'Upgrade Plan',
          nextPlan: null
        }
      } else {
        switch (this.pricingPlanIdentifier) {
          case 'basic':
            return {
              icon: 'upgrade',
              changePlanText: 'Upgrade Your Plan to ',
              changePlanDetails: 'Get more negotiations per month, Exit Intent, Negotiation on Cart, Bundling and much more.',
              buttonText: 'Change Plan',
              nextPlan: 'PROFESSIONAL'
            }
          case 'professional':
            return {
              icon: 'upgrade',
              changePlanText: 'Upgrade Your Plan to ',
              changePlanDetails: 'Get Unlimited Negotiations, Recovered Nibbles, Premium Support, Custom Reports, and much more.',
              buttonText: 'Change Plan',
              nextPlan: 'PREMIUM'
            }
          default:
            return {
              icon: 'mode_edit',
              changePlanText: 'Change Your Plan',
              changePlanDetails: 'Choose a different plan, or increase your monthly limit.',
              buttonText: 'Change Plan',
              nextPlan: ''
            }
        }
      }
    },
    usageType () {
      if (this.planIsInError) {
        return 'is-danger'
      } else {
        if (this.currentPlanIsFreeTrial) {
          return null
        } else {
          return 'is-info'
        }
      }
    },
    isShopifyStore () {
      return this.$store.getters.isShopifyStore
    }
  },
  watch: {
    $route () {
      this.shopifyChargeId = this.$route.query.charge_id
      this.loaded = false
      this.fetchData()
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      if (this.shopifyChargeId) {
        const params = {
          shopify_charge_id: this.shopifyChargeId
        }
        nibble.put(`/retailers/${this.retailerId}/account/plan`, params, this.$store.getters.authHeaders)
          .then(_ => {
            this.$store.dispatch('forceRetailerUpdate', this.retailerId)
          })
          .then((_) => {
            this.$router.push(`/retailers/${this.retailerId}/account/plan`)
          })
          .catch((error) => {
            nibble.handleNetworkError(error, this.$store)
          })
      } else {
        this.fetchCurrentPlan()
      }
    },
    fetchCurrentPlan () {
      nibble.get(`/retailers/${this.retailerId}/account/plan`, {}, this.$store.getters.authHeaders)
        .then((response) => {
          this.loaded = true
          this.pricingPlanIdentifier = response.data.pricing_plan.identifier
          this.billingSchemeName = response.data.name
          this.billingSchemeStatus = response.data.status
          this.subscriptionFee = response.data.subscription_fee_amount
          this.billingCycleType = response.data.billing_cycle_type
          this.billingCycleUsageCap = response.data.billing_cycle_usage_cap
          this.billingCycleMaxNibbles = response.data.billing_cycle_max_nibbles
          this.billingCycleUsedNibbles = response.data.billing_cycle_used_nibbles_count
          this.paymentType = response.data.payment_type
          this.usageFeePercentage = response.data.usage_fee_percentage
          this.email = response.data.email
          this.website = response.data.website
          this.timezone_code = response.data.timezone_code
          this.pendingScheme = response.data.pending_scheme
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    chooseAPlan () {
      if (this.pendingScheme != null && this.retailerId != null) {
        if (this.pendingScheme.usage_fee_percentage != null && this.pendingScheme.usage_fee_percentage > 0) {
          this.$refs.updateModal.show(this.pendingScheme.name, this.pendingScheme.usage_fee_percentage, this.pendingScheme.billing_cycle_usage_cap)
        } else {
          var params = { plan_identifier: this.pendingScheme.pricing_plan.identifier }
          nibble.put(`/retailers/${this.retailerId}/account/plan`, params, this.$store.getters.authHeaders)
            .then((response) => {
              if (response.data.status === 'redirect') {
                window.location.href = response.data.redirect_uri
              } else {
                this.$store.dispatch('forceRetailerUpdate', this.retailerId)
                  .then((retailer) => {
                    this.$router.push(`/retailers/${this.retailerId}/account/plan`)
                  })
              }
            })
            .catch((error) => {
              nibble.handleNetworkError(error, this.$store)
            })
        }
      } else if (this.retailerId != null) {
        this.$router.push(`/retailers/${this.retailerId}/account/plan/choice`)
      } else {
        this.$router.push('/session/new')
      }
    },
    getInTouch () {
      window.open('https://meetings.hubspot.com/jamieettedgui/book-a-training-call', '_blank')
    },
    changePassword () {
      nibble.post('/auth0/password', { retailer_id: this.retailerId }, this.$store.getters.authHeaders)
        .then((response) => {
          this.password_requested = true
          this.password_requested_error = false
        })
        .catch((error) => {
          this.password_requested = false
          this.password_requested_error = true
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.month-text {
  color: #668099;
  font-weight: 400;
}
.password-change-message {
  padding: 12px;
}
</style>
