<template>
  <page-layout active-menu-item="bot-test">
    <main-column :breadcrumbs="breadcrumbs" :page-title="breadcrumbs[0].title"
      content-class="main-column-sub-columns columns">
      <div class="column">
        <div class="main-column-content">
          <div class="columns">
            <div class="column">
              <div class="card">
                <div class="card-content">
                  <div class="columns">
                    <div class="column">
                      <h2 class="title is-4">Change Password</h2>
                      <div class="columns">
                        <div class="column is-full">
                          <p>Click to change your Admin password.</p>
                          <hr />
                          <div v-if="password_requested === false && password_requested_error === false">
                            <b-button type="is-rounded" @click="changePassword">Change Password</b-button>
                          </div>
                          <div v-if="password_requested" class="message password-change-message is-success">
                            <p>Please check your email for instructions on completing your password change. If you do
                              not receive it
                              within a few minutes, check your spam folder.</p>
                          </div>
                          <div v-if="password_requested_error" class="message password-change-message is-danger">
                            <p>There was an error requesting a password reset. Please try again later.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main-column>
  </page-layout>
</template>

<script>
import MainColumn from '@/components/MainColumn'
import PageLayout from '@/components/PageLayout'
import nibble from '@/utils/nibble'

export default {
  name: 'ChangePassword',
  components: { MainColumn, PageLayout },
  data () {
    return {
      password_requested: false,
      password_requested_error: false
    }
  },
  computed: {
    breadcrumbs () {
      return [
        { title: 'Change Password' }
      ]
    }
  },
  methods: {
    changePassword () {
      nibble.post('/auth0/password', { current: true }, this.$store.getters.authHeaders)
        .then((response) => {
          this.password_requested = true
          this.password_requested_error = false
        })
        .catch((error) => {
          this.password_requested = false
          this.password_requested_error = true
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.password-change-message {
  padding: 12px;
}
</style>
