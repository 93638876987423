<template>
  <b-modal :active="active" :can-cancel="false">
    <div class="box">
      <header>
        <h2 class="modal-card-title title-is-2">Message {{chatMessageId}}</h2>
        <b-button class="close-btn" @click="closeModal">
          <b-icon class="modal-close-icon" icon="close"></b-icon>
        </b-button>
      </header>
      <div class="columns" v-if="chatMessage">
        <div class="column">
          <div class="card mb-4">
            <div class="card-content">
              <chat-message :has-modal="false" :session="session" :message="chatMessage"></chat-message>
            </div>
          </div>
          <div class="card mb-4" v-if="chatMessage.intents.length > 0">
            <div class="card-content">
              <div class="card-head mb-4">
                <h2 class="title is-3">Intents</h2>
              </div>
              <table class="table is-fullwidth stats-table">
                <tbody>
                  <tr v-for="(intent, intentIndex) in chatMessage.intents" :key="intentIndex">
                    <td>{{intent.name}} @ {{intent.confidence}}</td>
                  </tr>
                </tbody>
              </table>
              <div class="has-text-left">
                <b-checkbox v-model="chatMessage.incorrect_intent_inference">Intent Inference is Incorrect</b-checkbox>
              </div>
            </div>
          </div>
          <div class="card mb-4" v-if="chatMessage.entities != null && chatMessage.entities.length > 0">
            <div class="card-content">
              <div class="card-head mb-4">
                <h2 class="title is-3">Entities</h2>
              </div>
              <table class="table is-fullwidth stats-table">
                <tbody>
                  <tr v-for="(entity, entityIndex) in chatMessage.entities" :key="entityIndex">
                    <td>{{entity.role}} ({{entity.dataType}}) @ {{entity.confidence}}</td>
                    <td class="has-text-right">{{entity.stringValue}}</td>
                  </tr>
                </tbody>
              </table>
              <div class="has-text-left">
                <b-checkbox v-model="chatMessage.incorrect_entity_inference">Entity Inference is Incorrect</b-checkbox>
              </div>
            </div>
          </div>
          <div class="card mb-4" v-if="chatMessage.interaction_data">
            <div class="card-content">
              <div class="card-head mb-4">
                <h2 class="title is-3">Interaction JSON</h2>
              </div>
              <div class="editor">
                <vue-monaco-editor v-model="interactionJson" language="json" :options="editorOptions" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-grouped is-grouped-right">
        <p class="buttons">
          <b-button type="is-text is-rounded" @click="closeModal">Cancel</b-button>
          <b-button type="is-primary is-rounded" @click="submit">Save</b-button>
        </p>
      </div>
    </div>
  </b-modal>
</template>

<script>
import nibble from '@/utils/nibble'
import { ToastProgrammatic as Toast } from 'buefy'

export default {
  name: 'ChatMessageModal',
  props: {
    session: {
      required: true,
      type: Object
    },
    chatMessageId: {
      required: true,
      type: Number
    }
  },
  beforeCreate () {
    // Workaround to avoid circular dependency
    this.$options.components.ChatMessage = require('./ChatMessage.vue').default
  },
  data () {
    return {
      active: false,
      chatMessage: null,
      editorOptions: {
        tabSize: 2
      }
    }
  },
  computed: {
    sessionId () {
      return this.session.session_id
    },
    interactionJson () {
      if (this.chatMessage && this.chatMessage.interaction_data) {
        return JSON.stringify(this.chatMessage.interaction_data, null, 2)
      } else {
        return ''
      }
    }
  },
  methods: {
    show () {
      this.fetchData()
      this.active = true
    },
    fetchData () {
      nibble.get(`/sessions/${this.sessionId}/chat_messages/${this.chatMessageId}`, {}, this.$store.getters.authHeaders)
        .then((response) => {
          this.chatMessage = response.data
        })
    },
    submit () {
      nibble.put(`/sessions/${this.sessionId}/chat_messages/${this.chatMessageId}`, this.chatMessage, this.$store.getters.authHeaders)
        .then((response) => {
          Toast.open({ message: 'Message Tagging Info Saved', type: 'is-primary' })
          this.closeModal()
        })
    },
    closeModal () {
      this.active = false
      this.chatMessage = null
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  // width: 800px;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  padding: 0px;
  gap: 9px;
  height: 32px;
  background-color: #fff;
}

header h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.modal-content {
  // max-width: 800px;
  height: 75%;
}

.modal-close-icon {
  color: #668099;
  background-color: #fff;
  border: none;
}

.close-btn {
  border: none;
}

.close-btn:focus {
  outline: none;
  box-shadow: none;
}
.editor {
  height: 500px;
}
</style>
