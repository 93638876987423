// src/auth.js

import { createAuth0Client } from '@auth0/auth0-spa-js'
import axios from 'axios'
import store from './store'
import Vue from 'vue'

let auth0Client = null

const state = Vue.observable({
  authError: null
})

const configureClient = async () => {
  auth0Client = await createAuth0Client({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID //,
    // audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    // scope: 'openid profile email'
  })
}

const initAuth = async () => {
  await configureClient()
  try {
    if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
      await auth0Client.handleRedirectCallback()
      window.history.replaceState({}, document.title, window.location.pathname)
    }
  } catch (err) {
    state.authError = err
  }

  const isAuthenticated = await auth0Client.isAuthenticated()
  if (isAuthenticated) {
    await mapAuth0UserToSystemUser()
  }
}

const mapAuth0UserToSystemUser = async () => {
  try {
    // use getIdTokenClaims

    const idTokenClaims = await auth0Client.getIdTokenClaims()

    // get the token from __raw idTokenClaims

    const token = idTokenClaims.__raw

    // Send it in the headers to the API
    const response = await axios.post(
      process.env.VUE_APP_API_PATH + '/auth0',
      {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    const systemUser = response.data

    // Commit the user to the Vuex store
    store.commit('login', systemUser)
  } catch (error) {
    console.error('Error mapping Auth0 user to system user:', error)
    state.authError = 'Could not map Auth0 user to system user.'
  }
}

const login = async () => {
  await auth0Client.loginWithRedirect({
    authorizationParams: {
      redirect_uri: window.location.origin //,
      // audience: process.env.VUE_APP_AUTH0_AUDIENCE
    }
  })
}

const logout = (route = null) => {
  if (!route) {
    route = ''
  }
  auth0Client.logout({
    logoutParams: {
      returnTo: window.location.origin + route
    }
  })
}

const getIdTokenClaims = async () => {
  return await auth0Client.getIdTokenClaims()
}

export { initAuth, login, logout, state, getIdTokenClaims }
