<template>
  <section class="section">
    <div class="columns is-centered">
      <div class="column is-6-tablet is-5-desktop is-5-widescreen">
        <div class="card">
          <div class="card-content">
            <div class="block has-text-centered">
              <img src="/images/nibble-logo.png" alt="Nibble. Converse. Convert." width="168" height="50">
            </div>

            <p>
              Please log in below to access your Nibble retailer account.
            </p>
            <hr>
            <div class="field is-grouped is-grouped-right">
              <div class="control buttons">
                <button @click="login" class="button is-primary is-rounded">Log In</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { login, logout, state } from '@/auth'

export default {
  name: 'Login',
  data () {
    return {
      error: null
    }
  },
  methods: {
    login () {
      login()
    },
    logout () {
      logout()
    }
  },
  watch: {
    'state.authError': function (newValue) {
      this.error = newValue
    }
  },
  computed: {
    // Damn lint...
    state () {
      return state
    }
  },
  created () {
    if (this.state && this.state.authError !== null) {
      this.error = this.state.authError
    } else {
      // Check if the current route is '/session/new'
      if (this.$route && this.$route.path === '/session/new') {
        this.login()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.is-flex {
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
</style>
